<template>
    <div>
        <p class="error">{{ error }}</p>
    
        <!-- <p class="decode-result">Last result: <b>{{ result }}</b></p> -->
        <p class="decode-result">Escanea el QR del paquete </p>
    
        <qrcode-stream @decode="onDecode" @init="onInit" />
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {

    components: { QrcodeStream },

    data() {
        return {
            result: '',
            error: ''
        }
    },

    methods: {
        onDecode(result) {
            this.result = result
        },

        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        },

    },
    watch: {
        result: function(val) {
            console.log('nuevo resultado!', val)
            // console.log('router', this.$router.push)
            // this.$router.push('/reparto/' + val.id)
            // this.$router.push({
            //      path: '/reparto/' + val,
            // })
            window.location.href = val


            // this.$emit('result', val)
        }
    }
}
</script>

<style scoped>
.error {
    font-weight: bold;
    color: red;
}
</style>
