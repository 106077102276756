<template>
	<div>
	
		<body class="font-mono">
			<!-- Container -->
			<div class="container mx-auto">
				<div class="flex justify-center px-6 my-12">
					<!-- Row -->
					<div class="w-full xl:w-3/4 lg:w-11/12 flex">
						<!-- Col -->
						<div class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg" style="background-image: url('https://source.unsplash.com/oWTW-jNGl9I/600x800')"></div>
						<!-- Col -->
						<div class="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
							<div class="px-8 mb-4 text-center">
								<h3 class="pt-4 mb-2 text-2xl">Estado del pedido</h3>
								<p class="mb-4 text-sm text-gray-700">
									Aqui podras ver el estado de tu pedido
								</p>
							</div>
							<div class="w-full">
								<h1 @click="scaner = true" v-if="!scaner" class="text-3xl my-8 bg-green-400 p-2 border-2 border-black cursor-pointer">Escanear QR</h1>
								<!-- <button @click="scanQR">Escanear QR</button> -->
								<QrScaner v-if="scaner" />
								<h1 @click="scaner = false" v-if="scaner" class="text-2xl my-4 bg-red-400 p-2 border-2 border-black cursor-pointer">Cerrar Scaner</h1>
	
							</div>
							<!-- <div class=" pt-6 pb-8 mb-4 w-full bg-white rounded" v-if="!scaner">
								<div class="mb-8 w-full">
									<label class="block mb-2 text-sm font-bold text-gray-700" for="text">
										ID de tu Pedido
									</label>
									<input class="w-full h-12 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="id" type="text" placeholder="ingrese id" v-model="idPedido" />
								</div>
								<div class="mb-6 text-center w-full">
									<router-link class="w-full  px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700 focus:outline-none focus:shadow-outline" :to="'/reparto/'+ idPedido">Buscar Pedido</router-link>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</body>
	</div>
</template>

<script>

// import QrScanner from 'qr-scanner';
// import 'qr-scanner/qr-scanner-worker.min.js';
import QrScaner from '../../components/QrScaner.vue'

export default {
	// layout: 'admin',
	// layour: "default"
	layout: 'reparto',
	data() {
		return {
			idPedido: '',
			scaner: false
		}
	},
	components: {
		QrScaner
	},
	methods: {
	}

}
</script>